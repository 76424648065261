import { useEffect, createContext, useReducer } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import axios from '../utils/axios';
import * as Sentry from '@sentry/nextjs';

// ----------------------------------------------------------------------

const initialState = {
  company: [],
  activeCompany: [],
};

const handlers = {
  SET_COMPANY: (state, action) => {
    const { company } = action.payload;
    return {
      ...state,
      company,
    }
  },
  SET_ACTIVE_COMPANY: (state, action) => {
    const { activeCompany } = action.payload;
    return {
      ...state,
      activeCompany,
    }
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const CompanyContext = createContext({
  ...initialState,
  getCompany: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  useEffect(() => {
    const initialize = async () => {
      await getCompany();
    };

    initialize();
  }, [user]);

  const getCompany = async () => {
    try {
      if (!user) return;
      const response = await axios.get(`/api/crews/company/`);
      const companyData = response.data.results;
      const activeCompanyData = companyData?.filter(company => company.is_active === true) || [];
      dispatch({
        type: 'SET_COMPANY',
        payload: {
          company: companyData
        },
      });
      dispatch({
        type: 'SET_ACTIVE_COMPANY',
        payload: {
          activeCompany: activeCompanyData
        },
      });
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        ...state,
        getCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export { CompanyContext, CompanyProvider };
