import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { default as plainAxios } from 'axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  application: null,
};

const handlers = {
  SET_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  SET_APPLICATION: (state, action) => {
    const { application } = action.payload;
    return {
      ...state,
      application,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getUser: () => Promise.resolve(),
  getApplication: () => Promise.resolve(),
  setApplication: () => Promise.resolve(),
  registerCrew: () => Promise.resolve(),
  requestPhoneCert: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          await getUser();
          getApplication();
        } else {
          dispatch({
            type: 'SET_USER',
            payload: {
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        if (err.response && err.response.status === 400) return ;
        dispatch({
          type: 'SET_USER',
          payload: {
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/api/accounts/login/', {
      email,
      password,
    });
    const { access, refresh } = response.data;
    setSession(access, refresh);
    await getUser();
    try {
      getApplication();
    } catch (error) {
      dispatch({
        type: 'SET_APPLICATION',
        payload: {
          application: null,
        },
      });
      console.error(error);
    }
  };

  const register = async (email, password, allowMarketing) => {
    const response = await plainAxios.post(`${process.env.HOST_API_KEY}/api/accounts/register/`, {
      email,
      password,
      register_type: 'crew',
      allow_email: allowMarketing,
      allow_sms: allowMarketing,
    });
    await login(email, password);
  };

  const logout = async () => {
    setSession(null);
    if (dataLayer) {
      dataLayer.push({"user_id": "", "event": "setUserId"});
    }
    dispatch({ type: 'LOGOUT' });
  };

  const requestPhoneCert = async (phone, countryCode) => {
    const response = await axios.post('/api/accounts/cert/phone/generate/', {
      reason: 'register',
      target_value: phone,
      country_code: countryCode,
    });
    return response.status;
  }

  const getUser = async () => {
    const responseMe = await axios.get('api/accounts/me/crew/');
    dispatch({
      type: 'SET_USER',
      payload: {
        user: responseMe.data
      },
    });
  };

  const setApplication = async (application) => {
    dispatch({
      type: 'SET_APPLICATION',
      payload: {
        application,
      },
    });
  };

  const getApplication = async () => {
    try {
      const response = await axios.get('/api/crews/application/');
      dispatch({
        type: 'SET_APPLICATION',
        payload: {
          application: response.data
        },
      });
    } catch (err) {
      dispatch({
        type: 'SET_APPLICATION',
        payload: {
          application: null,
        },
      });
      console.error(err);
    }
  };

  const registerCrew = async (name, nickname, position, countryCode, phone, phonecert) => {
    await axios.post('/api/accounts/register/crew/', {
      username: name,
      nickname,
      cert_code: phonecert,
      country_code: countryCode,
      phone,
      position,
    });

    await getUser();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        getUser,
        getApplication,
        setApplication,
        registerCrew,
        requestPhoneCert,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
