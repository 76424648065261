// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 70,
  MAIN_DESKTOP_OFFSET_HEIGHT: 70 - 32,
  DASHBOARD_DESKTOP_HEIGHT: 70,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_COMPANY_HEIGHT: 48,
  DASHBOARD_ITEM_ROOT_HEIGHT: 38,
  DASHBOARD_ITEM_SUB_HEIGHT: 36,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 20,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const cookiesExpires = 3;

export const cookiesKey = {
  themeMode: 'themeMode',
  themeDirection: 'themeDirection',
  themeColorPresets: 'themeColorPresets',
  themeLayout: 'themeLayout',
  themeStretch: 'themeStretch',
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'purple',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const WHATTIME_CALENDAR_ID = process.env.WHATTIME_CALENDAR_ID;

export const EVENT_ID = {
  apply: 'apply',  // 크루 지원서 작성시작 버튼
  crewRegister: 'crew-register',  // 크루 가입 버튼
  crewLogin: 'crew-login',  // 크루 로그인 버튼
  crewRequestPhoneCert: 'crew-request-phone-cert',  // 크루 전화번호 인증번호 요청 버튼
  crewPersonalInfo: 'crew-personal-info',  // 크루 이름, 전화번호 저장 버튼
  crewApply: 'crew-apply',  // 크루 지원하기 버튼 (스킬, 이력 입력)
  crewSetWorkTime: 'crew-set-work-time',  // 크루 합격자 업무 가능시간 저장 버튼
  crewSendResetPasswordEmail: 'crew-send-reset-password-email',  // 크루 비밀번호 재설정 이메일 보내기 버튼
  crewResetPassword: 'crew-reset-password',  // 크루 비밀번호 재설정 버튼
  crewFailReApply: 'crew-fail-re-apply',  // 크루 재지원 버튼
  crewConversionStart: 'crew-conversion-start',  // 크루 전환가입 핸드폰 번호 입력 버튼
  crewConversion: 'crew-conversion',  // 크루 전환가입 버튼
  // offer
  crewOfferAccept: 'crew-offer-accept',  // 크루 사전미팅 가능시간과 제안 수락 버튼
  crewOfferPass: 'crew-offer-pass',  // 크루 제안 거절 다이얼로그 열기 버튼
  crewOfferPassSubmit: 'crew-offer-pass-submit',  // 크루 제안 거절과 사유 제출 버튼
  crewOfferPassCancel: 'crew-offer-pass-cancel',  // 크루 제안 거절 취소 버튼
  crewOfferPassOff: 'crew-offer-pass-off',  // 크루 제안 거절하면서 제안받기 끄는 버튼
  crewOfferPassNotOff: 'crew-offer-pass-notoff',  // 크루 제안 거절하면서 제안받기는 계속하는 버튼
  // 3way confirm
  crewOffer3WayConfirm: 'crew-offer-3way-confirm',  // 크루 제안 사전미팅 시간확인 버튼
  crewOffer3WayRequestChat: 'crew-offer-3way-request-chat',  // 크루 제안 사전미팅 채팅요청 버튼 - 미팅 불가능한 시간으로 예약됨
  // negotiate
  crewNegotiateRejectSubmit: 'crew-negotiate-reject-submit',  // 크루 계약조건 제안 거절과 사유 제출 버튼
  crewNegotiateRejectCancel: 'crew-negotiate-reject-cancel',  // 크루 계약조건 제안 거절 취소 버튼
  crewNegotiateReject: 'crew-negotiate-reject',  // 크루 계약조건 제안 거절 다이얼로그 열기 버튼
  crewNegotiateSubmit: 'crew-negotiate-submit',  // 크루 계약조건 제안 버튼
  // settings
  crewSettingsContract: 'crew-settings-contract',  // 크루 설정페이지 계약 개인정보 저장 버튼
  // instant-matching
  crewInstantMatchingProfile: 'crew-im',  // 크루 인스턴트 매칭 프로필 작성하기 버튼
  crewInstantMatchingProfileShow: 'crew-im-show',  // 크루 인스턴트 매칭 프로필 상세보기 버튼
  crewInstantMatchingProfileStart: 'crew-im-start',  // 크루 인스턴트 매칭 프로필 설정 시작 버튼
  crewInstantMatchingProfileEdit: 'crew-im-edit',  // 크루 인스턴트 매칭 프로필 설정 수정 버튼
  crewInstantMatchingProfileOK: 'crew-im-ok',  // 크루 인스턴트 매칭 프로필 설정 확인 버튼
  crewInstantMatchingProfileStep: 'crew-im-step',  // 크루 인스턴트 매칭 프로필 설정 버튼들
  crewInstantMatchingProfileEditStep: 'crew-im-edit-step',  // 크루 인스턴트 매칭 프로필 설정 버튼들
};

export const INPUT_ID = {
  loginEmail: 'crew-input-login-email',  // 크루 로그인 폼의 이메일 인풋
  loginPassword: 'crew-input-login-password',  // 크루 로그인 폼의 비밀번호 인풋
  registerEmail: 'crew-input-register-email',  // 크루 가입 폼의 이메일 인풋
  registerPassword: 'crew-input-register-password',  // 크루 가입 폼의 비밀번호 인풋
  newpasswordPassword: 'crew-input-newpassword-password',  // 크루 비밀번호 재설정 폼의 비밀번호 인풋
  resetpasswordEmail: 'crew-input-resetpassword-email',  // 크루 비밀번호 재설정 메일 요청 폼의 이메일 인풋
  conversionEmail: 'crew-input-conversion-email',  // 크루 전환가입 폼의 이메일 인풋
  conversionPassword: 'crew-input-conversion-password',  // 크루 전환가입 폼의 비밀번호 인풋
  conversionPhone: 'crew-input-conversion-phone',  // 크루 전환가입 시작폼의 전화번호 끝 4자리 인풋
  // acceptForm
  offeracceptTimerange: 'crew-input-offeraccept-timerange',  // 크루 제안 수락폼 사전미팅 가능시간 인풋
  offeracceptComment: 'crew-input-offeraccept-comment',  // 크루 제안 수락폼 본인소개 인풋
  offeracceptSalary: 'crew-input-offeraccept-salary',  // 크루 제안 수락폼 인건비 인풋
  offeracceptSuggestion: 'crew-input-offeraccept-suggestion',  // 크루 제안 수락폼 4주할일 수정제안 인풋
  // passForm
  offerpassReason: 'crew-input-offer-passreason',  // 크루 제안 거절하기 폼의 거절사유 인풋
  // applyForm
  applyRoles: 'crew-input-apply-roles',  // 크루 지원 입력 폼 역할 인풋
  applyYears: 'crew-input-apply-years',  // 크루 지원 입력 폼 경력 인풋
  applySkills: 'crew-input-apply-skills',  // 크루 지원 입력 폼 기술 선택 인풋
  applyURL: 'crew-input-apply-url',  // 크루 지원 입력 폼 이력서 주소 인풋
  // personalInfoForm
  personalName: 'crew-input-personal-name',  // 크루 가입후 개인정보 입력 폼의 이름 인풋
  personalNickname: 'crew-input-personal-nickname',  // 크루 가입후 개인정보 입력 폼의 닉네임 인풋
  personalPosition: 'crew-input-personal-position',  // 크루 가입후 개인정보 입력 폼의 포지션 선택 인풋
  personalCountry: 'crew-input-personal-country',  // 크루 가입후 개인정보 입력 폼의 국가지역 인풋
  personalPhone: 'crew-input-personal-phone',  // 크루 가입후 개인정보 입력 폼의 전화번호 인풋
  personalPhonecert: 'crew-input-personal-phonecert',  // 크루 가입후 개인정보 입력 폼의 전화번호 인증번호 인풋
  // negotiation
  negotiateRejectreason: 'crew-input-negotiate-rejectreason',  // 크루 계약조건 제안 거절하기 폼의 거절사유 인풋
  negotiateStartdate: 'crew-input-negotiate-startdate',  // 크루 계약조건 제안 폼의 업무 시작일자 인풋
  negotiateSalary: 'crew-input-negotiate-salary',  // 크루 계약조건 제안 폼의 4주 인건비 인풋
  negotiateResource: 'crew-input-negotiate-resource',  // 크루 계약조건 제안 폼의 투입 리소스 셀렉트
  negotiateContacttime: 'crew-input-negotiate-contacttime',  // 크루 계약조건 제안 폼의 소통 가능시간 인풋
  negotiateTask: 'crew-input-negotiate-task',  // 크루 계약조건 제안 폼의 업무 업무내용 인풋
  // settings - contract
  settingRegistrationnumber: 'crew-input-setting-registrationnumber',  // 크루 설정 계약 개인정보 폼의 주민등록번호 인풋
  settingBankcode: 'crew-input-setting-bankcode',  // 크루 설정 계약 개인정보 폼의 은행이름 선택 인풋
  settingAccountnumber: 'crew-input-setting-accountnumber',  // 크루 설정 계약 개인정보 폼의 은행 계좌번호 인풋
  settingCertificate: 'crew-input-setting-certificate',  // 크루 설정 계약 개인정보 폼의 신분증 사본 파일 인풋
  // settings - matching
  settingOfferallow: 'crew-input-setting-offerallow',  // 크루 설정 매칭 폼의 제안받기 스위치 인풋
};
