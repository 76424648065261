// ----------------------------------------------------------------------

export default function Badge() {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          minWidth: 6,
          width: 6,
          height: 6,
        },
      },
    },
  };
}
