// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PropTypes from 'prop-types';
import cookie from 'cookie';
// react
import { useEffect } from 'react';
// next
import Head from 'next/head';
import App from 'next/app';
import Script from 'next/script';
import { useRouter } from 'next/router';
// datepicker locale
import ko from 'date-fns/locale/ko'
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
// utils
import { getSettings } from '../utils/settings';
import { GTM_ID, pageview } from '../utils/gtm';
// contexts
import { SettingsProvider } from '../contexts/SettingsContext';
// theme
import ThemeProvider from '../theme';
// components
import RtlLayout from '../components/RtlLayout';
import ProgressBar from '../components/ProgressBar';
import MotionLazyContainer from '../components/animate/MotionLazyContainer';

import NotistackProvider from '../components/NotistackProvider';
import { AuthProvider } from '../contexts/JWTContext';
import { CompanyProvider } from '../contexts/CompanyContext';

// ----------------------------------------------------------------------

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
  settings: PropTypes.object,
};

export default function MyApp(props) {
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      {/* Google Tag Manager - Global base code */}
      <Script
        id="before-gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: "window.dataLayer = window.dataLayer || [];"
        }}
      />
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />

      <AuthProvider>
        <CompanyProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko} localeText={customPtBRLocaleText}>
            <SettingsProvider defaultSettings={settings}>
              <ThemeProvider>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <RtlLayout>
                      <ProgressBar />
                      {getLayout(<Component {...pageProps} />)}
                    </RtlLayout>
                  </MotionLazyContainer>
                </NotistackProvider>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </CompanyProvider>
      </AuthProvider>
    </>
  );
}

MyApp.getInitialProps = async (context) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie);

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};

const customPtBRLocaleText = {
  okButtonLabel: "확인",
  cancelButtonLabel: "취소",
};
